import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showUpgradeNotification } from "reducers/newslettersReducer";
import api from "../dependencies/api";
import urls from "../urls";

export default function useCloneNewsletter() {
  const [cloning, setCloning] = useState(false);
  const [cloneError, setCloneError] = useState(undefined);
  const dispatch = useDispatch();
  const router = useRouter();

  const cloneNewsletter = (newsletterID) => {
    setCloning(true);

    api
      .post(`/clone_newsletter/${newsletterID}/`)
      .then((res) => {
        const id = res.data.cloned_newsletter_id;
        router.push(urls.editNewsletter(id));
      })
      .catch((err) => {
        if (err?.response?.data?.action === "upgrade") {
          dispatch(showUpgradeNotification(err?.response?.data?.detail));
        } else {
          setCloneError(err?.response?.data?.detail || "Error cloning newsletter");
        }
      })
      .finally(() => {
        setCloning(false);
      });
  };

  return { cloneNewsletter, cloning, cloneError };
}
