import { HStack, Input, P2, VStack } from "@mailbrew/uikit";
import InfoButton from "components/InfoButton";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, updateNewsletterField } from "reducers/newslettersReducer";
import useSWR from "swr";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";

const ReplyToEditor = ({ newsletterID, ...rest }) => {
  const dispatch = useDispatch();
  const { data: newsletter, mutate } = useSWR(`newsletters/${newsletterID}/`);
  const editorNewsletter = useSelector(currentNewsletterSelector);

  const [debouncedUpdateReplyTo] = useDebouncedCallback((description) => {
    /**
     * When we are in the brew editor, the current newsletter is in Redux, and we can update it directly
     * When not in the editor, we update the description field by making PATCH requests to the API directly.
     */
    if (editorNewsletter) {
      dispatch(updateNewsletterField("reply_to", description));
    } else {
      mutate({ ...newsletter, reply_to: replyTo }, false);
      api
        .patch(`/newsletters/${newsletter.id}/`, { reply_to: replyTo })
        .then()
        .catch((err) => alert(`${err?.response?.reply_to?.[0] ?? err}`));
    }
  }, 500);

  const [replyTo, setReplyTo, replyToError] = useInputValidator(
    newsletter?.reply_to ?? "",
    Yup.string().email("Must be a valid email").max(128, "Too long."),
    (value) => debouncedUpdateReplyTo(value)
  );

  return (
    <VStack {...rest}>
      <HStack align="stretch">
        <P2>Reply-to:</P2>
        <InfoButton text="When subscribers hit reply, they'll reply to this address. The brew will still be sent from hello@mailbrew.com." />
      </HStack>
      <Input
        placeholder="Your Best Email"
        value={replyTo}
        onChange={(e) => setReplyTo(e.target.value)}
        error={replyToError}
      />
    </VStack>
  );
};

export default ReplyToEditor;
