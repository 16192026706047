import { Modal, Spacer } from "@mailbrew/uikit";
import { ManageSubscribersUI } from "pages/edit/[brewId]/subscribers";

export default function ManageSubscribersModal({ newsletter, show, setShow }) {
  return (
    <Modal
      p={0}
      closeButton={{ bottomSheetOffset: 6 }}
      fullHeight
      bottomSheet
      bottomSheetWidth="900px"
      show={show}
      setShow={setShow}
    >
      <Spacer size={4} />
      <ManageSubscribersUI newsletter={newsletter} />
    </Modal>
  );
}
