import moment from "moment";
import { Fragment } from "react";
import useInputValidator from "../hooks/useInputValidator";
import { StyledButtonSelect } from "./ScheduleEditor";

const DayOfMonthSelector = (props) => {
  const { value, onChange } = props;

  const [dayOfMonth, setDayOfMonth, dayOfMonthError] = useInputValidator(value, validateDayOfMonth, (value) => {
    const dayOfMonth = parseInt(value);
    onChange(dayOfMonth);
  });

  const ordinalDay = dayOfMonth ? moment(dayOfMonth + " Jan 2020").format("Do") : "";

  return (
    <Fragment>
      <StyledButtonSelect options={monthDays} selectedOption={dayOfMonth.toString()} onChange={setDayOfMonth}>
        {ordinalDay}
      </StyledButtonSelect>
      {dayOfMonthError && `, (${dayOfMonthError})`}
    </Fragment>
  );
};

const monthDays = new Array(31).fill(0).map((_, index) => index + 1);

const validateDayOfMonth = (value) => {
  const number = parseInt(value);

  if (isNaN(number)) {
    return "Please insert a number";
  }
  if (!(1 <= number && number <= 31)) {
    return "Must be between 1 and 31";
  }

  return null;
};

export default DayOfMonthSelector;
