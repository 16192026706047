import { atom } from "recoil";

/* ------------------------------- Brew Editor ------------------------------ */

export const editorSidebarShownState = atom({
  key: "editorSidebarShown",
  default: true,
});

export const selectedBrewIDState = atom({
  key: "selectedBrewID",
  default: null,
});
