import { DropDownMenu, useConfig } from "@mailbrew/uikit";
import useDeleteBrew from "hooks/useDeleteBrew";
import useDuplicateBrew from "hooks/useDuplicateBrew";
import usePauseBrew from "hooks/usePauseBrew";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import useCloneNewsletter from "../hooks/useCloneNewsletter";
import { authUserSelector } from "../reducers/authReducer";
import { BrewPublishModal } from "./PublishBrewButton";

const BrewManageOptionsDropdown = ({ brew, author, forceIsSelf, handleGenerateNewIssue, children }) => {
  const config = useConfig();
  const user = useSelector(authUserSelector);
  const isSelf = author?.id === user?.id || forceIsSelf;

  const [publishModalShown, setPublishModalShown] = useState(false);

  const { public: newsletterPublic } = brew;
  const [isNewsletterPublic, setIsNewsletterPublic] = useState(newsletterPublic);

  const { cloneNewsletter } = useCloneNewsletter();

  const handleCloneClick = async () => {
    const confirmed = window.confirm(`This will create your own personal copy of "${brew.title}". Continue?`);
    if (confirmed) {
      cloneNewsletter(brew.id);
    }
  };

  const handleDeleteClick = useDeleteBrew(brew);
  const handleClickDuplicate = useDuplicateBrew(brew);
  const { isPaused, togglePaused } = usePauseBrew(brew);

  const handleSelectOption = (option) => {
    if (isSelf) {
      selfOptions.find((o) => o.value === option).handler();
    } else {
      publicOptions.find((o) => o.value === option).handler();
    }
  };

  const selfOptions = [
    handleGenerateNewIssue && {
      value: "generate",
      name: "New issue",
      icon: "refresh",
      handler: handleGenerateNewIssue,
    },
    {
      value: "delete",
      name: "Delete brew",
      icon: "trashBold",
      handler: handleDeleteClick,
    },
    {
      value: "duplicate",
      name: "Duplicate",
      icon: "copy",
      handler: handleClickDuplicate,
    },
    {
      value: "publish",
      name: "Share",
      icon: "shareAlt",
      handler: () => setPublishModalShown(true),
    },
    {
      value: "togglePaused",
      name: isPaused ? "Resume" : "Pause",
      icon: isPaused ? "playBold" : "pauseBold",
      handler: togglePaused,
    },
  ].filter((o) => !!o);

  const publicOptions = [
    {
      value: "clone",
      name: "Clone and Customize",
      icon: "copy",
      handler: handleCloneClick,
    },
  ];

  return (
    <Fragment>
      <DropDownMenu
        icon={!children && "dots"}
        iconColor={config.colors.c4}
        options={isSelf ? selfOptions.map((o) => o.value) : publicOptions.map((o) => o.value)}
        optionsNames={isSelf ? selfOptions.map((o) => o.name) : publicOptions.map((o) => o.name)}
        optionsIcons={isSelf ? selfOptions.map((o) => o.icon) : publicOptions.map((o) => o.icon)}
        onSelect={handleSelectOption}
      >
        {children}
      </DropDownMenu>
      <BrewPublishModal
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={brew}
        show={publishModalShown}
        setShow={setPublishModalShown}
      />
    </Fragment>
  );
};

export default BrewManageOptionsDropdown;
