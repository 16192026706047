import { useEffect } from "react";
import usePrevious from "./usePrevious";

const defaultOptions = { ignorePrevUndefined: true };

export default function useOnValueChange(value, callback, options) {
  options = { ...options, ...defaultOptions };
  const prev = usePrevious(value);

  useEffect(() => {
    let changed = !Object.is(value, prev);
    if (options.ignorePrevUndefined && typeof prev === "undefined") {
      changed = false;
    }

    if (changed) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, prev, options]);
}
