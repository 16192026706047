import { useDispatch, useSelector } from "react-redux";
import { Input } from "@mailbrew/uikit";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";
import useInputValidator from "hooks/useInputValidator";
import { currentNewsletterSelector, updateNewsletterField } from "reducers/newslettersReducer";
import api from "dependencies/api";
import useSWR, { mutate } from "swr";

const DescriptionEditor = ({ newsletterID }) => {
  const dispatch = useDispatch();
  const { data: newsletter } = useSWR(`newsletters/${newsletterID}/`);
  const editorNewsletter = useSelector(currentNewsletterSelector);

  const [debouncedUpdateDescription] = useDebouncedCallback((description) => {
    /**
     * When we are in the brew editor, the current newsletter is in Redux, and we can update it directly
     * When not in the editor, we update the description field by making PATCH requests to the API directly.
     */
    if (editorNewsletter) {
      dispatch(updateNewsletterField("description", description));
    } else {
      mutate(`newsletters/${newsletterID}/`, { ...newsletter, description }, false);
      api
        .patch(`/newsletters/${newsletter.id}/`, { description })
        .then()
        .catch((err) => alert(`${err?.response?.data?.detail ?? err}`));
    }
  }, 500);

  const [description, setDescription, descriptionError] = useInputValidator(
    newsletter?.description ?? "",
    Yup.string().max(128, "Please write a shorter description."),
    (description) => debouncedUpdateDescription(description)
  );

  return (
    <Input
      name="description"
      component="textarea"
      height="55px"
      autoComplete="off"
      type="text"
      placeholder="Brew description..."
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      error={descriptionError}
      style={{ width: "100%" }}
    />
  );
};

export default DescriptionEditor;
