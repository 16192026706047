import { notif, useTimeout } from "@mailbrew/uikit";
import api from "dependencies/api";
import { useCallback, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import apiErrMsg from "utils/apiErrMsg";
import confirmPromise from "utils/confirmPromise";
import notifApiError from "utils/notifApiError";
import { pluralize } from "utils/pluralize";

const updateMessagesDelay = 6_000;
const generationTimeout = 90_000;

export default function useGenerateBrew(brew, onGenerationCompeted, options) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [issueID, setIssueID] = useState(null);
  const notifIdRef = useRef(null);

  const { notifMessage = "Generating your brew..." } = options || {};

  const startGeneration = useCallback(async () => {
    // ask confirmation before generating, if brew has subscribers
    if (brew.subscribers_count > 0) {
      const confirmed = await confirmPromise(
        `${brew.subscribers_count} ${pluralize("subscriber", brew.subscribers_count)} will get this brew. Are you sure?`
      );
      if (!confirmed) {
        return;
      }
    }

    try {
      const res = await api.post(`/newsletters/${brew.id}/generate/`);
      setIssueID(res.data.issue_id);
      setIsGenerating(true);
      notifIdRef.current = notif.loading(notifMessage, { duration: 60_000 });
    } catch (err) {
      notifApiError(err);
    }
  }, [brew]);

  const { data: issue } = useSWR(issueID && `/issues/${issueID}/`, isGenerating && { refreshInterval: 1 });

  useEffect(() => {
    if (!issue) return;

    const success = issue.status === "published";
    const failed = ["generation_failed", "generation_failed", "no_content"].includes(issue.status);

    if (isGenerating && success) {
      notif.success("Enjoy your brew 🙌", { duration: 5000, id: notifIdRef.current });
      setIsGenerating(false);
      onGenerationCompeted(issue);
    } else if (isGenerating && failed) {
      setIsGenerating(false);

      const msg =
        issue.status === "no_content"
          ? issue.serial_number === 1
            ? "Your brew has no content, try adding some sources"
            : "No new content found since last issue"
          : `Generation failed (${issue.status})`;

      notif.error(msg, { id: notifIdRef.current, duration: 10_000 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerating, issue]);

  // progress and timeouts

  useTimeout(
    () => {
      if (isGenerating) {
        notif.loading("This can take up to a minute...", { id: notifIdRef.current });
      }
    },
    updateMessagesDelay * 1,
    [isGenerating]
  );

  useTimeout(
    () => {
      if (isGenerating) {
        notif.loading("Almost done...", { id: notifIdRef.current });
      }
    },
    updateMessagesDelay * 4,
    [isGenerating]
  );

  useTimeout(
    () => {
      if (isGenerating) {
        notif.error("Something went wrong", { id: notifIdRef.current, duration: 10_000 });
        setIsGenerating(false);
      }
    },
    generationTimeout,
    [isGenerating]
  );

  return { startGeneration, isGenerating };
}
