const SCHEDULES_VERSION = 1;

export const makeDailySchedule = (times) => ({
  version: SCHEDULES_VERSION,
  type: "daily",
  times,
});

export const makeWeeklySchedule = (weekdays, times) => ({
  version: SCHEDULES_VERSION,
  type: "weekly",
  weekdays,
  times,
});

export const makeMonthlySchedule = (day_of_month, times) => ({
  version: SCHEDULES_VERSION,
  type: "monthly",
  day_of_month,
  times,
});

export const makeTime = (hour, minute) => ({
  hour,
  minute,
});

export const MO = 0;
export const TU = 1;
export const WE = 2;
export const TH = 3;
export const FR = 4;
export const SA = 5;
export const SU = 6;

export const allWeekdaysNumbers = [MO, TU, WE, TH, FR, SA, SU];
export const weekdaysNumbersNoWeekend = [MO, TU, WE, TH, FR];
export const weekdaysNumbersJustWeekend = [SA, SU];
export const allWeekdaysSingleLetter = ["M", "T", "W", "T", "F", "S", "S"];
export const allWeekdaysDoubleLetter = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
export const allWeekdaysLong = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export function migrateSchedule(schedule) {
  if (schedule.version === 1) {
    if (schedule.type === "daily" && schedule.exclude_weekends) {
      return makeWeeklySchedule(weekdaysNumbersNoWeekend, schedule.times);
    }
  }

  // version 0 -> 1
  if (!schedule.version) {
    switch (schedule.type) {
      case "daily":
        return makeDailySchedule([makeTime(schedule.hour, schedule.minute)]);
      case "weekly":
        return makeWeeklySchedule(
          [allWeekdaysLong.indexOf(schedule.day_of_week)],
          [makeTime(schedule.hour, schedule.minute)]
        );
      case "monthly":
        return makeMonthlySchedule(schedule.day_of_month, [makeTime(schedule.hour, schedule.minute)]);
      default:
        return makeDailySchedule([makeTime(9, 0)]);
    }
  }

  return schedule;
}
