import { Icon, Stack, useConfig } from "@mailbrew/uikit";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { Fragment } from "react";
import prefers12HoursFormat from "../utils/detectTimeFormatPreference";
import { makeTime } from "../utils/schedules";
import { StyledButtonSelect } from "./ScheduleEditor";

const MAX_TIMES = 3;
const prefers12Hours = prefers12HoursFormat();

const TimeSelector = (props) => {
  const { times, setTimes, allowMultiple = true, onClose } = props;

  const handleRemoveTime = (index) => () => {
    setTimes(times.filter((_, i) => i !== index));
  };

  const handleAddTime = (index) => () => {
    const time = times[index];
    const nextHour = Math.round(Math.min(time.hour + 4, 23));
    setTimes([...times, makeTime(nextHour, time.minute)]);
  };

  function handleSelect(selectedIndex, timeIndex) {
    const selectedTime = timeOptions[selectedIndex];
    setTimes(
      times.map((time, i) => {
        if (i === timeIndex) return selectedTime;
        return time;
      })
    );
    onClose && onClose();
  }

  return (
    <Fragment>
      {times.map((time, timeIndex) => {
        const formattedSelectedOption = formatTime(time);
        const selectedOptionIndex = timeOptions.findIndex((t) => t.hour === time.hour && t.minute === time.minute);
        return (
          <Fragment key={timeIndex}>
            <Stack style={{ display: "inline-flex" }} gap={0} key={`${time.hour}${time.minute}`}>
              <StyledButtonSelect
                options={timeOptions}
                formatOption={formatTime}
                selectedOption={selectedOptionIndex}
                onChange={(selectedIndex) => handleSelect(selectedIndex ?? 0, timeIndex)}
              >
                {formattedSelectedOption}
              </StyledButtonSelect>
              {/* only show remove on first time item if there are other items */}
              {allowMultiple && timeIndex === times.length - 1 && (
                <RemoveTimeButton
                  onClick={
                    (timeIndex === 0 && times.length > 1) || timeIndex > 0 ? handleRemoveTime(timeIndex) : undefined
                  }
                  disabled={!((timeIndex === 0 && times.length > 1) || timeIndex > 0)}
                />
              )}
              {/* show add button only on last item */}
              {allowMultiple && (
                <AddTimeButton
                  onClick={
                    timeIndex === times.length - 1 && times.length < MAX_TIMES ? handleAddTime(timeIndex) : undefined
                  }
                  disabled={!(timeIndex === times.length - 1 && times.length < MAX_TIMES)}
                />
              )}
            </Stack>
            {timeIndex < times.length - 1 && <span style={{ marginLeft: "1px" }}>, </span>}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const timeOptions = (() => {
  let timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let quarter = 0; quarter < 4; quarter++) {
      timeOptions = [...timeOptions, { hour: hour, minute: quarter * 15 }];
    }
  }
  return timeOptions;
})();

function formatTime(time) {
  return moment(`${time.hour}:${time.minute}`, "HH:mm").format(prefers12Hours ? "h:mm a" : "HH:mm");
}

const RemoveTimeButton = (props) => {
  const config = useConfig();
  if (props.disabled) return null;
  return <Icon ml="3px" color={config.colors.c3} name="minus" size="13px" offset="-1px" {...props} />;
};
const AddTimeButton = (props) => {
  const config = useConfig();
  if (props.disabled) return null;
  return <Icon ml="3px" color={config.colors.c3} name="plus" size="13px" offset="-1px" {...props} />;
};

export default TimeSelector;
