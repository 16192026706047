import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * Usage:
 * const [hour, setHour, hourError] = useInputValidator(newsletter.schedule.hour, isNumber, (value) => { dispatch(setHour(value)) })
 */
function useInputValidator(currentValue, validateOrSchema, commitValue) {
  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState(currentValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _validateOrSchema = useCallback(validateOrSchema, []);
  const skipCommitOnNextChange = useRef(true); // true = don't commit on first render

  const error = useMemo(() => {
    if (!touched) return null;
    return validateValue(value, _validateOrSchema);
  }, [touched, _validateOrSchema, value]);

  // commit value on changes
  useEffect(() => {
    if (skipCommitOnNextChange.current) {
      skipCommitOnNextChange.current = false;
      return;
    }

    // don't commit if there is an error
    if (error) return;

    commitValue(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  // update value when currentValue changes
  useEffect(() => {
    // skip next commit to avoid commit the same value twice
    // (we are updating the internal value given the external value, changed by a commit already)
    skipCommitOnNextChange.current = true;
    setValue(currentValue);
  }, [currentValue]);

  return [
    value,
    function (value) {
      // always process commit when changes come from the user
      skipCommitOnNextChange.current = false;
      setTouched(true);
      setValue(value);
    },
    error,
  ];
}

function validateValue(value, validateOrSchema) {
  // Custom validation function
  if (typeof validateOrSchema === "function") {
    return validateOrSchema(value);
  }
  // Yup object schema
  else if (typeof validateOrSchema === "object") {
    try {
      validateOrSchema.validateSync(value);
      return null;
    } catch (err) {
      return err.message;
    }
  }
}

export default useInputValidator;
