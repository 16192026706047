const stretchStyle = {
  flex: "1 1 auto",
  overflowX: "hidden",
  paddingBottom: "2px",
  paddingTop: "2px",
  marginBottom: "-2px",
  marginTop: "-2px",
  maxWidth: "100%",
  display: "flex",
};

export default stretchStyle;
