import api from "dependencies/api";
import { useSetRecoilState } from "recoil";
import { selectedBrewIDState } from "recoilState";
import { mutate } from "swr";
import notifApiError from "utils/notifApiError";
import { keyHomeBrews } from "utils/swrKeys";

export default function useDuplicateBrew(brew) {
  const setSelectedBrewId = useSetRecoilState(selectedBrewIDState);

  return async () => {
    try {
      const res = await api.post(`/duplicate_newsletter/${brew.id}/`);
      mutate(keyHomeBrews, (cache) => [res.data, ...cache], false);
      setSelectedBrewId(res.data.id);
    } catch (err) {
      notifApiError(err, "Can't copy this brew");
    }
  };
}
