import api from "dependencies/api";
import { mutate } from "swr";
import notifApiError from "utils/notifApiError";
import { keyBrewById, keyHomeBrews } from "utils/swrKeys";

export default function useDeleteBrew(brew): () => void {
  return async () => {
    const confirmed = window.confirm(`Delete "${brew.title}"? This cannot be undone.`);

    if (confirmed) {
      try {
        await api.delete(keyBrewById(brew.id));
        mutate(keyHomeBrews, (brews) => brews.filter((b) => b.id !== brew.id), false);
      } catch (err) {
        notifApiError(err, "Can't delete your brew");
      }
    }
  };
}
