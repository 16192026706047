import { Button, cleanedProps, Icon, notif, useDetectBrowser } from "@mailbrew/uikit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import plausible from "utils/plausible";
import createShareURL from "../utils/createShareURL";
import mixpanel from "mixpanel-browser";

const BrewShareButton = (props) => {
  const { newsletter, iconOnly, wrapperStyle, noIcon } = props;
  const cleanProps = cleanedProps(props, ["newsletter", "iconOnly", "noIcon"]);

  const { hasTouch } = useDetectBrowser();

  if (!newsletter) return null;

  const link = createShareURL(newsletter);
  const title = newsletter.title;

  if (!iconOnly) {
    if (navigator.share && hasTouch) {
      return (
        <Button
          icon="link"
          {...cleanProps}
          plausibleProps={{ title, link }}
          onClick={() => {
            try {
              navigator.share({
                url: link,
              });
            } catch (err) {
              // navigator.share throws if the user cancels sharing
            }
          }}
        >
          Share
        </Button>
      );
    } else {
      return (
        <CopyToClipboard text={link} onCopy={() => notif.success("Link copied")} style={wrapperStyle}>
          <Button icon={!noIcon && "link"} plausibleProps={{ title, link }} {...cleanProps}>
            Share
          </Button>
        </CopyToClipboard>
      );
    }
  } else {
    if (navigator.share && hasTouch) {
      return (
        <Icon
          name="link"
          {...cleanProps}
          onClick={() => {
            trackShare(title, link);
            try {
              navigator.share({
                url: link,
              });
            } catch {}
          }}
        >
          Share
        </Icon>
      );
    } else {
      return (
        <CopyToClipboard
          text={link}
          onCopy={() => {
            trackShare(title, link);
            notif.success("Link copied");
          }}
        >
          <Icon name="link" {...cleanProps}>
            Share
          </Icon>
        </CopyToClipboard>
      );
    }
  }
};

function trackShare(title, link) {
  mixpanel.track(`Click on Share Brew`);
  plausible.track("Click on Share Brew", {
    title,
    link,
  });
}

export default BrewShareButton;
