import api from "dependencies/api";
import { useState } from "react";
import { mutate } from "swr";
import notifApiError from "utils/notifApiError";
import { keyBrewById, keyHomeBrews } from "utils/swrKeys";

export default function usePauseBrew(brew) {
  const [isPaused, setIsPaused] = useState(brew.paused);

  async function togglePaused() {
    try {
      setIsPaused(!isPaused);
      const res = await api.patch(keyBrewById(brew.id), { paused: !isPaused });
      setIsPaused(res.data.paused);

      mutate(keyHomeBrews, (cache) => {
        return cache.map((brew) => {
          if (brew.id === res.data.id) {
            return res.data;
          }

          return brew;
        });
      });
    } catch (err) {
      setIsPaused(isPaused); // restore to value before optimistic update
      notifApiError(err, "Error pausing your brew");
    }
  }

  return {
    isPaused,
    togglePaused,
  };
}
